.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #faf7f6;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 100;

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  @media (max-height: 560px) {
    align-items: flex-start;
    padding-top: 60px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 25px;

    .icon {
      font-size: 18px;
    }
  }

  &__content {
    width: 100%;
  }

  &__items {

    a {
      font-size: 32px;
      font-weight: 300;
      line-height: 0.88;
      color: #1e1e30;
      display: block;
      text-decoration: none;
      text-align: center;
      margin-bottom: 34px;
      @media (max-height: 450px) {
        margin-bottom: 20px;
      }


      &.small {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        margin-bottom: 14px;
      }
    }

    .split {
      height: 20px;
      @media (max-height: 450px) {
        height: 15px;
      }
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    height: 30px;
    @media (max-height: 560px) {
      margin-top: 40px;
    }

    @media (max-height: 450px) {
      margin-top: 20px;
    }

    a {
      text-decoration: none;
      font-size: 30px;
      margin: 0 6px;
      color: #1e1e30;
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    margin-top: 46px;

    @media (max-height: 450px) {
      margin-top: 20px;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #1e1e30;
      margin: 0 12px;
      text-decoration: none;
    }
  }

  &__copyright {
    opacity: 0.3;
    font-size: 12px;
    font-weight: 500;
    color: #1e1e30;
    text-align: center;
    margin-top: 2px;
  }

}
