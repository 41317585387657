.newsletter {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #3b0fc8;
  @media (max-width: 767px) {
    height: initial;
    margin-bottom: 70px;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    @media (max-width: 767px) {
      display: block;
      padding: 0;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    color: #faf7f6;
    margin-right: 23px;
    @media (max-width: 767px) {
      padding-right: 20px;
      margin-bottom: 15px;
      line-height: 1.56;
    }
  }

  &__info {
    font-size: 12px;
    color: #faf7f6;
    font-weight: 500;
    padding-left: 10px;
    @media (max-width: 767px) {
      padding-left: 0;
      margin-top: 10px;
    }
  }

  &__input {
    width: 314px;
    border-bottom: 2px solid #faf7f6;
    position: relative;
    @media (max-width: 767px) {
      height: 32px;
      width: 100%;
      max-width: 400px;
    }

    .icon {
      color: #faf7f6;
      position: absolute;
      font-size: 24px;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      color: #faf7f6;
      padding-left: 0;
      padding-bottom: 5px;
      padding-right: 40px;

      &:focus {
        outline: none;
      }
    }

    &__placeholder {
      font-size: 14px;
      color: #faf7f6;
      padding-left: 0;
      padding-bottom: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }


    &--error {
      border-bottom-color: red;

      input {
        color: red;
      }

      .newsletter__input__placeholder {
        color: red;
      }

      .icon {
        color: red;
      }
    }
  }
}
