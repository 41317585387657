.blog-post-item {
  $self: &;

  &--small {
    width: 280px;
  }

  &--medium {
    width: 380px;
  }

  &--large {
    width: 480px;
  }

  &__image {
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 20px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: #1e1e30;
    //word-break: break-all;

    #{$self}--no-img & {
      font-size: 22px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.63;
    color: #1e1e30;
    margin-top: 12px;
  }

  a {
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #1e1e30;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #1e1e30;
    text-decoration: none;
  }

  &--no-img {
    width: 100%;

    a {
      width: initial;
      height: initial;
      display: inline-flex;
      border: none;
    }
  }
}
