.icon {
line-height: 1;
}

.icon:before {
font-family: barwise !important;
font-style: normal;
font-weight: normal !important;
vertical-align: top;
}
