.banner {
  padding-bottom: 94px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
    }
  }

  &__left {
    max-width: 550px;
    padding-top: 40px;

    @media (max-width: 767px) {
      max-width: initial;
    }

    h1 {
      font-family: 'Brygada 1918', serif;
      font-size: 58px;
      font-weight: 500;
      line-height: 1.24;
      color: #1e1e30;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 36px;
        text-align: center;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.56;
      color: #1e1e30;
      margin: 12px 0 0 0;

      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }


  &__download {
    margin-top: 48px;
    width: 204px;
    height: 48px;
    border-radius: 6px;
    background-color: #3b0fc8;
    font-size: 14px;
    font-weight: 500;
    color: #faf7f6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 24px;
    }
    @media (max-width: 767px) {
      margin: 24px auto 0 auto;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    //remove after video
    video {
      max-width: 550px;
      min-width: 350px;
      margin-top: 20px;
      width: 100%;
      margin-left: 20px;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  &__video {
    width: 274.4px;
    height: 523.1px;
    border-radius: 33.9px;
    border: solid 2px #3b0fc8;
    background-color: #faf7f6;
    position: relative;
    margin-right: 128px;
    margin-top: 10px;

    @media (min-width: 768px) and (max-width: 1239px) {
      margin-right: 30px;
      margin-left: 40px;
    }

    @media (max-width: 767px) {
      margin-top: 54px;
      margin-right: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 33.9px;
      border: solid 2px #3b0fc8;
      background-color: #faf7f6;
      left: 14px;
      top: 14px;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 33.9px;
      border: solid 2px #3b0fc8;
      background-color: #faf7f6;
      left: 28px;
      top: 28px;
      z-index: -2;
    }
  }
}
