@import "./../settings/_colors";
@import "./../settings/breakpoints";

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;

  * {
    font-variant: normal;
    font-variant-numeric: normal;
    box-sizing: border-box;
  }
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  background-color: #faf7f5;
}

input,textarea {

  font-family: 'Poppins', sans-serif;
}


.container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  @media (min-width: 900px) and (max-width: 1239px) {
    padding: 0 60px;
  }

  @media (min-width: 768px) and (max-width: 899px) {
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

#root {
  width: 100%;
  height: 100%;
}
