.menu-fixed {
  height: 70px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #faf7f6;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  transform: translateY(-70px);
  transition: transform 0.2s;

  &.active {
    transform: translateY(0);
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 46px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  nav {
    padding-left: 7px;
    margin-bottom: 5px;

    @media (max-width: 767px) {
      display: none;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #1e1e30;
      text-decoration: none;
      margin: 0 25px;
      position: relative;

      &.active {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          border-radius: 3px;
          background-color: #1e1e30;
          height: 2px;
          bottom: -3px;
          left: 0;
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__download {
    width: 204px;
    height: 48px;
    border-radius: 6px;
    background-color: #3b0fc8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #faf7f6;
    cursor: pointer;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__mobile {
    position: absolute;
    right: 9px;
    top: 12px;
    display: none;

    @media (max-width: 767px) {
      display: block;
    }

    .icon {
      font-size: 46px;
    }
  }
}
