.menu {
  height: 108px;
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 0;
    height: initial;
    padding-top: 12px;
  }

  a {
    img {
      display: block;
    }
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      display: block;
    }
  }


  nav {
    padding-left: 110px;
    margin-bottom: 5px;
    @media (min-width: 768px) and (max-width: 950px) {
      padding-left: 60px;
      flex-shrink: 0;
    }
    @media (max-width: 767px) {
      display: none;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #1e1e30;
      text-decoration: none;
      margin: 0 25px;
      position: relative;

      @media (min-width: 768px) and (max-width: 950px) {
        margin: 0 18px;
      }

      img {
        display: block;
      }

      &.active {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          border-radius: 3px;
          background-color: #1e1e30;
          height: 2px;
          bottom: -3px;
          left: 0;
        }
      }
    }
  }


  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  &__search {
    @media (max-width: 767px) {
      padding-top: 30px;
      flex-grow: 1;

      .search {
        width: 100%;
      }
    }
  }

  &__mobile {
    position: absolute;
    right: 9px;
    top: 12px;
    display: none;

    @media (max-width: 767px) {
      display: block;
    }

    .icon {
      font-size: 46px;
    }
  }
}
