.home-blog {
  padding-top: 108px;
  padding-bottom: 170px;
  @media (min-width: 768px) and (max-width: 1239px) {
    padding-bottom: 114px;
  }

  @media (max-width: 767px) {
    padding-top: 79px;
    padding-bottom: 79px;
  }

  h2 {
    text-align: center;
    font-size: 42px;
    font-weight: 300;
    line-height: 1.24;
    color: #1e1e30;
    margin-bottom: 68px;

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 1.36;
      margin-bottom: 37px
    }
  }

  .container {
    display: flex;
  }

  &__posts {
    display: flex;
    @media (min-width: 768px) and (max-width: 1239px) {
      width: 100%;
      justify-content: center;
    }

    .blog-post-item {
      &:first-child {
        margin-right: 20px;
        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    &-other {
      margin-left: 20px;
      padding: 44px 33px 30px 33px;
      width: 380px;
      border-radius: 12px;
      background-color: #efecea;

      @media (min-width: 768px) and (max-width: 1239px) {
        display: none;
      }

      @media (max-width: 767px) {
        display: none;
      }

      .blog-post-item {
        &:first-child {
          margin-bottom: 48px;
        }
      }
    }
  }


  &__resources {
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;

    a {
      width: 87px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #3b0fc8;
      font-size: 12px;
      font-weight: 500;
      color: #faf7f6;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
