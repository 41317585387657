/*
@font-face {
  font-family: 'GreycliffCF';
  src: url('/fonts/GreycliffCF-Light.eot');
  src: url('/fonts/GreycliffCF-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/GreycliffCF-Light.woff2') format('woff2'),
  url('/fonts/GreycliffCF-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}*/

@font-face {
  font-family: "barwise";
  src: url("/fonts/barwise.ttf?a52a52af0d15b1839146211348941d21") format("truetype");
}
