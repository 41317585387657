.introduction {
  @media (min-width: 900px) and (max-width: 1239px) {
    padding: 0 60px;
  }
  @media (min-width: 768px) and (max-width: 899px) {
    padding: 0 15px;
  }

  &__container {
    border-radius: 38px;
    background-color: #edeae7;
    padding: 100px 0 189px 0;
    max-width: 1425px;
    margin: 0 auto;

    @media (min-width: 768px) and (max-width: 1239px) {
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      border-radius: 0;
      padding: 50px 0 90px 0;
    }

    h1 {
      font-size: 42px;
      font-weight: 300;
      line-height: 1.24;
      text-align: center;
      color: #1e1e30;
      margin: 0 0 120px 0;

      @media (min-width: 768px) and (max-width: 1239px) {
        margin-bottom: 96px;
      }

      @media (max-width: 767px) {
        font-size: 28px;
        margin-bottom: 90px;
      }

      span {
        &.color1 {
          color: #fd7373;
        }

        &.color2 {
          color: #fa931b;
        }
      }
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 220px;

    @media (min-width: 768px) and (max-width: 1239px) {
      margin-bottom: 90px
    }

    @media (max-width: 767px) {
      display: block;
      margin-bottom: 48px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__left {
      width: 100%;
      max-width: 500px;
      margin-right: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 1;

      @media (min-width: 900px) and (max-width: 1239px) {
        margin-right: 47px;
        object {
          width: 100%;
        }
      }

      @media (min-width: 768px) and (max-width: 899px) {
        margin-right: 20px;
        object {
          width: 100%;
        }
      }

      @media (max-width: 767px) {
        max-width: initial;
        margin-right: 0;
        object {
          width: 100%;
        }
      }
    }

    &__right {
      width: 490px;

      @media (min-width: 768px) and (max-width: 1239px) {
        flex-shrink: 0;
        width: 340px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 48px;
        text-align: center;
      }

      h2 {
        font-family: 'Brygada 1918', serif;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.28;
        color: #1e1e30;
        margin: 0;
      }

      p {
        font-size: 18px;
        line-height: 1.56;
        color: #1e1e30;
        margin: 14px 0 0 0;
      }
    }
  }
}
