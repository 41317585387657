.download-bar {
  height: 70px;
  background: #3b0fc8 url('/images/header.png') no-repeat;

  @media (max-width: 767px) {
    display: none;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #faf7f6;
    position: relative;
    padding-right: 10px;

    img {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }

  &__download {
    font-size: 14px;
    font-weight: 500;
    color: #faf7f6;
    text-align: right;
    margin-left: 41px;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1239px) {
      margin-right: 50px
    }
  }
}
