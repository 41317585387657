.search {
  width: 224px;
  height: 48px;
  border-radius: 6px;
  background-color: #edeae7;
  display: flex;
  align-items: center;
  padding-right: 17px;

  .icon {
    font-size: 21px;
    cursor: pointer;
  }

  input {
    background: none;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: #1e1e30;
    padding-left: 27px;

    &:focus {
      outline: none;
    }
  }
}
