.contact {
  overflow: hidden;

  .container {
    display: flex;
    padding-top: 117px;
    position: relative;
    padding-bottom: 142px;
    margin-bottom: 222px;
    margin-top: 40px;

    @media (max-width: 1024px) {
      display: block;
    }

    @media (max-width: 767px) {
      margin-top: 34px;
      padding-top: 56px;
      margin-bottom: 0;
      padding-bottom: 72px;
    }

    &:before {
      position: absolute;
      content: '';
      width: 1425px;
      border-radius: 38px;
      background-color: #edeae7;
      height: 100%;
      left: -123px;
      top: 0;
      z-index: -1;

      @media (min-width: 900px) and (max-width: 1024px) {
        width: calc(100% - 120px);
        left: 60px;
      }

      @media (min-width: 768px) and (max-width: 899px) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }
  }

  &__badges {
    position: absolute;
    left: 325px;
    top: -160px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__left {
    width: 380px;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 580px;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 1024px) {
      margin: 0 auto;
    }

    .address {
      margin-top: 230px;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    h1 {
      font-family: Brygada1918;
      font-size: 48px;
      font-weight: normal;
      line-height: 1.21;
      color: #1e1e30;
      margin: 0 0 9px 0;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 28px;
      }
      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 1.28;
      }
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.63;
      color: #1e1e30;
      margin: 0;
    }

    img {
      display: block;
      width: 46px;
      margin-bottom: 16px;
    }
  }

  &__right {
    position: relative;
    width: 480px;
    flex-shrink: 0;
    margin-left: 32px;
    padding-top: 150px;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 580px;
      padding-top: 46px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-top: 38px;
    }

    @media (max-width: 1024px) {
      margin: 0 auto;
    }

    form {
      position: relative;
    }

    .success-msg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-color: #cecac6;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &__center {
        .icon {
          font-size: 38px;
        }

        b {
          display: block;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.33;
          text-align: center;
          color: #1e1e30;
          margin-top: 29px;
        }
      }

      &__back {
        bottom: 13px;
        right: 15px;
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: #1e1e30;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          font-size: 24px;
          margin-right: 6px;
          transform: rotate(-180deg);
        }
      }
    }

    input, textarea {
      width: 100%;
      height: 62px;
      display: flex;
      border-radius: 6px;
      background-color: #cecac6;
      border: 0;
      padding-left: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #1e1e30;
      margin-bottom: 12px;

      &::-webkit-input-placeholder {
        color: #1e1e30;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #1e1e30;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #1e1e30;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #1e1e30;
        opacity: 1;
      }

      &:focus {
        outline: none;
      }

      &.error {
        background-color: #fbe1e1;
        color: #e45959;

        &::-webkit-input-placeholder {
          color: #e45959;
          opacity: 1;
        }

        &::-moz-placeholder {
          color: #e45959;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #e45959;
          opacity: 1;
        }

        &:-moz-placeholder {
          color: #e45959;
          opacity: 1;
        }
      }
    }

    .error-msg {
      font-size: 14px;
      color: #1e1e30;
      margin-top: 30px;

      .icon {
        font-size: 20px;
        margin-right: 6px;
      }
    }

    textarea {
      padding-top: 16px;
      height: 184px;
      box-sizing: border-box;
      resize: none;
    }

    button {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 6px;
      background-color: #3b0fc8;
      font-size: 14px;
      font-weight: 500;
      color: #faf7f6;
      cursor: pointer;
      margin-top: 37px;

      @media (max-width: 767px) {
        margin-top: 28px;
      }

      .icon {
        margin-left: 12px;
        font-size: 20px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

