.about {
  .container {
    display: flex;
    padding: 80px 0;
    @media (max-width: 1024px) {
      padding: 50px 10px;
    }
  }

  &__left {
    flex-shrink: 0;

    @media (max-width: 989px) {
      width: 280px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    padding-left: 25px;

    @media (max-width: 767px) {
      padding-left: initial;
    }

    h1 {
      font-family: Brygada1918;
      font-size: 40px;
      font-weight: normal;
      line-height: 1.17;
      color: #1e1e30;
      margin: 0;
      @media (max-width: 989px) {
        font-size: 28px;
      }
    }

    .contact-link {
      width: 327px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #3b0fc8;
      font-size: 14px;
      font-weight: 500;
      color: #faf7f6;
      text-decoration: none;
      margin-top: 50px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }
}
