.footer {
  height: 362px;
  background: #3b0fc8 url('/images/footer.png') top right no-repeat;

  @media (max-width: 767px) {
    height: 415px;
  }

  .container {
    height: calc(100% - 70px);
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: block;
      height: 100%;
    }
  }

  &__left {
    padding-top: 65px;
  }

  &__right {
    padding-top: 176px;

    @media (max-width: 767px) {
      padding-top: 25px;
      text-align: right;
    }

    a {
      @media (max-width: 767px) {
        margin-left: 12px !important;
        margin-right: 0 !important;
      }
    }
  }

  &__links {
    a {
      font-size: 14px;
      font-weight: 500;
      color: #faf7f6;
      margin: 0 12px;
      text-decoration: none;


      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  &__copyright {
    opacity: 0.3;
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    color: #faf7f6;
    text-align: right;
    padding-right: 12px;
    margin-top: 2px;
    @media (max-width: 767px) {
      padding-right: 0;
    }
  }

  &__socials {
    margin-top: 50px;

    @media (max-width: 767px) {
      margin-top: 15px;
    }

    a {
      text-decoration: none;
      font-size: 30px;
      margin: 0 12px;
      color: #faf7f6;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  &__newsletter {
    @media (max-width: 767px) {
      display: none;
    }
  }


  &__mobile__newsletter {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}
