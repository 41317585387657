.blog-list {
  padding-bottom: 116px;

  @media (max-width: 767px) {
    padding-bottom: 65px;
  }

  &__row {
    display: flex;
    margin: 0 -10px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 !important;
      padding-top: 0 !important;

      & > div.blog-post-item {
        width: 100%;
        margin: 40px 0 0 0 !important;
        padding-top: 0 !important;
      }
    }

    .blog-post-item {
      margin: 0 10px;
    }

    &:nth-child(1) {
      .blog-post-item {
        &:nth-child(1) {
          padding-top: 50px;
        }

        &:nth-child(2) {
          padding-top: 222px;
        }
      }
    }

    &:nth-child(2) {
      padding-top: 25px;

      .blog-post-item {
        &:nth-child(1) {
          //margin-top: -36px;
        }

        &:nth-child(2) {
          padding-top: 158px;
        }

        &:nth-child(3) {
        }
      }
    }

    &:nth-child(3) {
      padding-top: 56px;

      .blog-post-item {
        &:nth-child(1) {
          padding-top: 102px;
        }

        &:nth-child(2) {
          //padding-top: 167px;
        }

        &:nth-child(3) {
          padding-top: 92px;
        }
      }
    }
  }

  .pagination {
    margin-top: 127px;
    @media (max-width: 767px) {
      margin-top: 80px;
    }
  }
}
