.blog-detail {
  padding-bottom: 240px;
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }

  &__title {
    font-family: 'Brygada 1918', serif;
    font-size: 58px;
    line-height: 1.17;
    color: #1e1e30;
    padding-right: 310px;
    margin: 0;
    font-weight: normal;

    @media (min-width: 768px) and (max-width: 1239px) {
      padding-right: 0;
    }

    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 1.28;
      padding-right: 0;
    }
  }

  &__date {
    font-family: 'Brygada 1918', serif;
    font-size: 16px;
    font-style: italic;
    line-height: 1;
    color: #1e1e30;
    margin-top: 12px;
  }

  &__image {
    max-width: 679px;
    overflow: hidden;
    border-radius: 12px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__head {
    margin-top: 53px;
    display: flex;
    margin-bottom: 90px;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }

    &__left {
      padding-right: 26px;
      @media (min-width: 768px) and (max-width: 1239px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        padding-right: 0;
      }

      p {
        font-size: 22px;
        line-height: 1.45;
        color: #1e1e30;
        margin: 0;
      }
    }

    &__right {
      flex-shrink: 0;
      @media (min-width: 768px) and (max-width: 1239px) {
        width: 50%;
        img {
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        img {
          width: 100%;
        }
      }
    }

    &__social {
      margin-top: 30px;
      display: flex;
      align-items: center;

      span {
        font-family: Brygada1918;
        font-size: 16px;
        font-style: italic;
        color: #1e1e30;
        margin-right: 18px;
      }

      a {
        text-decoration: none;
        font-size: 30px;
        margin: 0 6px;
        color: #1e1e30;
        @media (max-width: 767px) {
          margin-left: 0;
        }

        .icon {
          display: block;
        }
      }
    }
  }

  &__body {
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.63;
    color: #1e1e30;

    h6 {
      font-size: 24px;
      font-weight: 600;
    }

    ul {
      margin-top: 20px;
      @media (max-width: 767px) {
        padding-left: 20px
      }

      li {
        margin-bottom: 24px;
      }
    }
  }

  &__other-posts {
    display: flex;
    margin: 170px -10px 0 -10px;

    @media (min-width: 768px) and (max-width: 1239px) {
      margin-top: 136px;
    }

    @media (max-width: 767px) {
      margin-top: 60px;
    }

    .blog-post-item {
      margin: 0 10px;

      &:nth-child(3) {
        @media (min-width: 768px) and (max-width: 1239px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }

      &:nth-child(2) {

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
