
  %icon-burger-menu {
  content: "\f101";
  }

  .icon-burger-menu:before {
  content: "\f101";
  }

  %icon-close {
  content: "\f102";
  }

  .icon-close:before {
  content: "\f102";
  }

  %icon-contact {
  content: "\f103";
  }

  .icon-contact:before {
  content: "\f103";
  }

  %icon-facebook {
  content: "\f104";
  }

  .icon-facebook:before {
  content: "\f104";
  }

  %icon-instagram {
  content: "\f105";
  }

  .icon-instagram:before {
  content: "\f105";
  }

  %icon-right-arrow {
  content: "\f106";
  }

  .icon-right-arrow:before {
  content: "\f106";
  }

  %icon-search {
  content: "\f107";
  }

  .icon-search:before {
  content: "\f107";
  }

  %icon-twitter {
  content: "\f108";
  }

  .icon-twitter:before {
  content: "\f108";
  }
