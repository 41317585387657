.privacy-policy {
  max-width: 680px;
  margin: 0 auto;
  padding-top: 130px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 1024px) {
    padding-top:50px
  }
}

.full-text {
  line-height: 1.5;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  h1 {
    font-size: 32px;
    color: #1e1e30;
    font-weight: normal;
    margin: 0 0 30px 0;
  }

  a {
    word-break: break-all;
  }

  table {
    border-collapse: collapse;
    @media (max-width: 359px) {
      font-size: 12px;
    }

    td {
      padding: 10px;
      border: 1px solid #000;

      @media (max-width: 767px) {
        padding: 5px;
      }
    }
  }
}
