.download-modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &__back {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
  }

  &__content {
    width: calc(100% - 20px);
    max-width: 724px;
    height: 596px;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    .store {
      width: calc(50% - 7px);
      border-radius: 12px;
      background-color: #edeae7;
      height: 100%;
      padding: 43px 0 50px 0;

      &__title {
        font-family: Brygada1918;
        font-size: 36px;
        line-height: 1.28;
        text-align: center;
        color: #1e1e30;
        margin-bottom: 18px;
        margin-top: 29px;
      }

      &__text {
        font-size: 14px;
        line-height: 1.71;
        text-align: center;
        color: #1e1e30;
      }

      &__qr {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        img {
          width: 155px;
          height: 155px;
          display: block;
        }
      }

      &__link {
        display: block;
        margin: 40px auto 0 auto;
        width: 120px;
      }
    }
  }
}
