.blog-categories {
  padding-bottom: 60px;
  padding-top: 50px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
    padding-top: 44px;
  }


  .container {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      overflow: auto;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #1e1e30;
      position: relative;
      text-decoration: none;
      margin-left: 24px;
      @media (max-width: 767px) {
        white-space: nowrap;
      }

      &.active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          height: 2px;
          width: 100%;
          border-radius: 3px;
          background-color: #1e1e30;
          bottom: -3px;
        }
      }
    }
  }
}
