.pagination {
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin: 0 6px;
      border: solid 1px #1e1e30;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;


      &.selected {
        background: #1e1e30;

        a {
          color: #FFF;
        }
      }

      @media (max-width: 767px) {
        &:not(.previous), &:not(.next) {
          display: none;
        }

        &.previous {
          display: block;
        }

        &.next {
          display: block;
        }
      }

      a {
        font-size: 14px;
        font-weight: 600;
        color: #212b36;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.previous {
        transform: rotate(-180deg);
        left: -58px;
        @media (max-width: 767px) {
          left: -100px
        }
      }

      &.next {
        right: -58px;
        @media (max-width: 767px) {
          right: -100px
        }
      }

      &.previous, &.next {
        border: none;
        position: absolute;

        .icon {
          font-size: 24px;

          height: 32px;
          display: flex;
          align-items: center;
        }
      }

      &.disabled {
        display: none;
      }
    }
  }
}
