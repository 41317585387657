.search-list {
  padding-bottom: 100px;
  padding-top: 50px;

  @media (max-width: 767px) {
    padding-bottom: 0;
    padding-top: 0;
  }

  & > .container {
    padding: 0 100px;

    @media (min-width: 900px) and (max-width: 1239px) {
      padding: 0 64px;
    }
    @media (min-width: 768px) and (max-width: 899px) {
      padding: 0 15px;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  &__title {
    margin-bottom: 40px;

    @media (min-width: 768px) and (max-width: 1239px) {
      margin-bottom: 25px
    }

    @media (max-width: 767px) {
      margin-bottom: 0;
    }

    &__top {
      display: flex;
      align-items: center;

      .icon {
        font-size: 24px;
        margin-right: 24px
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1e1e30;
      }
    }

    b {
      font-size: 28px;
      font-weight: 300;
      color: #1e1e30;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
  }

  .blog-post-item {
    border-bottom: 2px solid #1e1e30;
    padding: 36px 0;

    &:last-child {
      border-bottom: none;
    }

    &--no-img {
      .blog-post-item__title {
        font-size: 24px;
        padding-right: 280px;

        @media (min-width: 768px) and (max-width: 1239px) {
          padding-right: 0;
        }
        @media (max-width: 767px) {
          padding-right: 0
        }
      }

      .blog-post-item__text {
        padding-right: 200px;

        @media (min-width: 768px) and (max-width: 1239px) {
          padding-right: 180px;
        }
        @media (max-width: 767px) {
          padding-right: 0
        }
      }

      a {
        float: right;
        width: 120px;
        height: 32px;
        margin-top: -30px;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: solid 1px #1e1e30;

        @media (min-width: 768px) and (max-width: 1239px) {
          margin-top: -50px
        }

        @media (max-width: 767px) {
          float: none;
          margin-top: 18px;
        }

      }
    }
  }
}
